import { staticClient } from '../api';
const FETCH_MOBILE_PAGE_REQUEST = 'FETCH_MOBILE_PAGE_REQUEST';
const FETCH_MOBILE_PAGE_SUCCESS = 'FETCH_MOBILE_PAGE_SUCCESS';
const FETCH_MOBILE_PAGE_FAIL = 'FETCH_MOBILE_PAGE_FAIL';

const fetchMobilePage = function () {
  let slug = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'index';
  let locale = arguments.length > 1 ? arguments[1] : undefined;
  return dispatch => {
    dispatch({
      type: FETCH_MOBILE_PAGE_REQUEST,
      slug,
      locale
    });
    const filename = "".concat(slug).concat(locale ? ".".concat(locale) : '', ".html");
    return staticClient.get("/instance/mobile/".concat(filename)).then(_ref => {
      let {
        data: html
      } = _ref;
      dispatch({
        type: FETCH_MOBILE_PAGE_SUCCESS,
        slug,
        locale,
        html
      });
      return html;
    }).catch(error => {
      dispatch({
        type: FETCH_MOBILE_PAGE_FAIL,
        slug,
        locale,
        error
      });
      throw error;
    });
  };
};

export { FETCH_MOBILE_PAGE_REQUEST, FETCH_MOBILE_PAGE_SUCCESS, FETCH_MOBILE_PAGE_FAIL, fetchMobilePage };