'use strict';

/** Soapbox audio clip. */

/** Produce HTML5 audio from sound data. */
const createAudio = sources => {
  const audio = new Audio();
  sources.forEach(_ref => {
    let {
      type,
      src
    } = _ref;
    const source = document.createElement('source');
    source.type = type;
    source.src = src;
    audio.appendChild(source);
  });
  return audio;
};
/** Play HTML5 sound. */


const play = audio => {
  if (!audio.paused) {
    audio.pause();

    if (typeof audio.fastSeek === 'function') {
      audio.fastSeek(0);
    } else {
      audio.currentTime = 0;
    }
  }

  audio.play();
};
/** Middleware to play sounds in response to certain Redux actions. */


export default function soundsMiddleware() {
  const soundCache = {
    boop: createAudio([{
      src: require('../../sounds/boop.ogg'),
      type: 'audio/ogg'
    }, {
      src: require('../../sounds/boop.mp3'),
      type: 'audio/mpeg'
    }]),
    chat: createAudio([{
      src: require('../../sounds/chat.oga'),
      type: 'audio/ogg'
    }, {
      src: require('../../sounds/chat.mp3'),
      type: 'audio/mpeg'
    }])
  };
  return () => next => action => {
    var _action$meta;

    if ((_action$meta = action.meta) !== null && _action$meta !== void 0 && _action$meta.sound && soundCache[action.meta.sound]) {
      play(soundCache[action.meta.sound]);
    }

    return next(action);
  };
}