import escapeTextContentForBrowser from 'escape-html';
import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import emojify from 'soapbox/features/emoji/emoji';
import { normalizeStatus } from 'soapbox/normalizers';
import { regexFromFilters } from 'soapbox/selectors';
import { simulateEmojiReact, simulateUnEmojiReact } from 'soapbox/utils/emoji_reacts';
import { stripCompatibilityFeatures, unescapeHTML } from 'soapbox/utils/html';
import { makeEmojiMap, normalizeId } from 'soapbox/utils/normalizers';
import { EMOJI_REACT_REQUEST, UNEMOJI_REACT_REQUEST } from '../actions/emoji_reacts';
import { STATUS_IMPORT, STATUSES_IMPORT } from '../actions/importer';
import { REBLOG_REQUEST, REBLOG_FAIL, UNREBLOG_REQUEST, UNREBLOG_FAIL, FAVOURITE_REQUEST, UNFAVOURITE_REQUEST, FAVOURITE_FAIL } from '../actions/interactions';
import { STATUS_CREATE_REQUEST, STATUS_CREATE_FAIL, STATUS_MUTE_SUCCESS, STATUS_UNMUTE_SUCCESS, STATUS_REVEAL, STATUS_HIDE, STATUS_DELETE_REQUEST, STATUS_DELETE_FAIL, STATUS_TRANSLATE_SUCCESS, STATUS_APPLY_FILTERS } from '../actions/statuses';
import { TIMELINE_DELETE } from '../actions/timelines';
const domParser = new DOMParser();

const minifyStatus = status => {
  return status.mergeWith((o, n) => n || o, {
    account: normalizeId(status.getIn(['account', 'id'])),
    reblog: normalizeId(status.getIn(['reblog', 'id'])),
    poll: normalizeId(status.getIn(['poll', 'id'])),
    quote: normalizeId(status.getIn(['quote', 'id']))
  });
}; // Gets titles of poll options from status


const getPollOptionTitles = _ref => {
  let {
    poll
  } = _ref;

  if (poll && typeof poll === 'object') {
    return poll.options.map(_ref2 => {
      let {
        title
      } = _ref2;
      return title;
    });
  } else {
    return ImmutableList();
  }
}; // Gets usernames of mentioned users from status


const getMentionedUsernames = status => {
  return status.mentions.map(_ref3 => {
    let {
      acct
    } = _ref3;
    return "@".concat(acct);
  });
}; // Creates search text from the status


const buildSearchContent = status => {
  const pollOptionTitles = getPollOptionTitles(status);
  const mentionedUsernames = getMentionedUsernames(status);
  const fields = ImmutableList([status.spoiler_text, status.content]).concat(pollOptionTitles).concat(mentionedUsernames);
  return unescapeHTML(fields.join('\n\n')) || '';
}; // Only calculate these values when status first encountered
// Otherwise keep the ones already in the reducer


export const calculateStatus = function (status, oldStatus) {
  let expandSpoilers = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  if (oldStatus && oldStatus.content === status.content && oldStatus.spoiler_text === status.spoiler_text) {
    return status.merge({
      search_index: oldStatus.search_index,
      contentHtml: oldStatus.contentHtml,
      spoilerHtml: oldStatus.spoilerHtml,
      hidden: oldStatus.hidden
    });
  } else {
    const spoilerText = status.spoiler_text;
    const searchIndex = domParser.parseFromString(buildSearchContent(status), 'text/html').documentElement.textContent || '';
    const emojiMap = makeEmojiMap(status.emojis);
    return status.merge({
      search_index: searchIndex,
      contentHtml: stripCompatibilityFeatures(emojify(status.content, emojiMap)),
      spoilerHtml: emojify(escapeTextContentForBrowser(spoilerText), emojiMap),
      hidden: expandSpoilers ? false : spoilerText.length > 0
    });
  }
}; // apply filters on a status

const fixFilters = (status, filters) => {
  const regex = regexFromFilters(filters);
  const filtered = Boolean(regex && regex.test(status.search_index));
  return status.merge({
    hidden: status.hidden || filtered,
    filtered
  });
}; // Check whether a status is a quote by secondary characteristics


const isQuote = status => {
  return Boolean(status.pleroma.get('quote_url'));
}; // Preserve quote if an existing status already has it


const fixQuote = (status, oldStatus) => {
  if (oldStatus && !status.quote && isQuote(status)) {
    return status.set('quote', oldStatus.quote).updateIn(['pleroma', 'quote_visible'], visible => visible || oldStatus.pleroma.get('quote_visible'));
  } else {
    return status;
  }
};

const fixStatus = (state, status, expandSpoilers, filters) => {
  const oldStatus = state.get(status.id);
  return normalizeStatus(status).withMutations(status => {
    fixQuote(status, oldStatus);
    calculateStatus(status, oldStatus, expandSpoilers);
    fixFilters(status, filters);
    minifyStatus(status);
  });
};

const importStatus = (state, status, expandSpoilers, filters) => state.set(status.id, fixStatus(state, status, expandSpoilers, filters));

const importStatuses = (state, statuses, expandSpoilers, filters) => state.withMutations(mutable => statuses.forEach(status => importStatus(mutable, status, expandSpoilers, filters)));

const translateStatus = (state, statusId, language, text) => state.updateIn([statusId, 'translations', language], () => text);

const deleteStatus = (state, id, references) => {
  references.forEach(ref => {
    state = deleteStatus(state, ref[0], []);
  });
  return state.delete(id);
};

const incrementReplyCount = (state, _ref4) => {
  let {
    in_reply_to_id
  } = _ref4;

  if (in_reply_to_id) {
    return state.updateIn([in_reply_to_id, 'replies_count'], 0, count => {
      return typeof count === 'number' ? count + 1 : 0;
    });
  } else {
    return state;
  }
};

const decrementReplyCount = (state, _ref5) => {
  let {
    in_reply_to_id
  } = _ref5;

  if (in_reply_to_id) {
    return state.updateIn([in_reply_to_id, 'replies_count'], 0, count => {
      return typeof count === 'number' ? Math.max(0, count - 1) : 0;
    });
  } else {
    return state;
  }
};
/** Simulate favourite/unfavourite of status for optimistic interactions */


const simulateFavourite = (state, statusId, favourited) => {
  const status = state.get(statusId);
  if (!status) return state;
  const delta = favourited ? +1 : -1;
  const updatedStatus = status.merge({
    favourited,
    favourites_count: Math.max(0, status.favourites_count + delta)
  });
  return state.set(statusId, updatedStatus);
}; // When filters are fetched we want to apply them to already retrieved status


const applyFilters = (state, filters) => {
  return state.map(status => fixFilters(status, filters));
};

const initialState = ImmutableMap();
export default function statuses() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case STATUS_APPLY_FILTERS:
      return applyFilters(state, action.filters);

    case STATUS_IMPORT:
      return importStatus(state, action.status, action.expandSpoilers, action.filters);

    case STATUSES_IMPORT:
      return importStatuses(state, action.statuses, action.expandSpoilers, action.filters);

    case STATUS_TRANSLATE_SUCCESS:
      return translateStatus(state, action.statusId, action.language, action.text);

    case STATUS_CREATE_REQUEST:
      return action.editing ? state : incrementReplyCount(state, action.params);

    case STATUS_CREATE_FAIL:
      return action.editing ? state : decrementReplyCount(state, action.params);

    case FAVOURITE_REQUEST:
      return simulateFavourite(state, action.status.id, true);

    case UNFAVOURITE_REQUEST:
      return simulateFavourite(state, action.status.id, false);

    case EMOJI_REACT_REQUEST:
      return state.updateIn([action.status.get('id'), 'pleroma', 'emoji_reactions'], emojiReacts => simulateEmojiReact(emojiReacts, action.emoji));

    case UNEMOJI_REACT_REQUEST:
      return state.updateIn([action.status.get('id'), 'pleroma', 'emoji_reactions'], emojiReacts => simulateUnEmojiReact(emojiReacts, action.emoji));

    case FAVOURITE_FAIL:
      return state.get(action.status.get('id')) === undefined ? state : state.setIn([action.status.get('id'), 'favourited'], false);

    case REBLOG_REQUEST:
      return state.setIn([action.status.get('id'), 'reblogged'], true);

    case REBLOG_FAIL:
      return state.get(action.status.get('id')) === undefined ? state : state.setIn([action.status.get('id'), 'reblogged'], false);

    case UNREBLOG_REQUEST:
      return state.setIn([action.status.get('id'), 'reblogged'], false);

    case UNREBLOG_FAIL:
      return state.get(action.status.get('id')) === undefined ? state : state.setIn([action.status.get('id'), 'reblogged'], true);

    case STATUS_MUTE_SUCCESS:
      return state.setIn([action.id, 'muted'], true);

    case STATUS_UNMUTE_SUCCESS:
      return state.setIn([action.id, 'muted'], false);

    case STATUS_REVEAL:
      return state.withMutations(map => {
        action.ids.forEach(id => {
          if (!(state.get(id) === undefined)) {
            map.setIn([id, 'hidden'], false);
          }
        });
      });

    case STATUS_HIDE:
      return state.withMutations(map => {
        action.ids.forEach(id => {
          if (!(state.get(id) === undefined)) {
            map.setIn([id, 'hidden'], true);
          }
        });
      });

    case STATUS_DELETE_REQUEST:
      return decrementReplyCount(state, action.params);

    case STATUS_DELETE_FAIL:
      return incrementReplyCount(state, action.params);

    case TIMELINE_DELETE:
      return deleteStatus(state, action.id, action.references);

    default:
      return state;
  }
}