import "core-js/modules/es.array.includes.js";
import { Map as ImmutableMap, List as ImmutableList, OrderedSet as ImmutableOrderedSet, Record as ImmutableRecord, fromJS } from 'immutable';
import sample from 'lodash/sample';
import { ACCOUNT_BLOCK_SUCCESS, ACCOUNT_MUTE_SUCCESS, ACCOUNT_UNFOLLOW_SUCCESS } from '../actions/accounts';
import { GROUP_REMOVE_STATUS_SUCCESS } from '../actions/groups';
import { STATUS_CREATE_REQUEST, STATUS_CREATE_SUCCESS } from '../actions/statuses';
import { TIMELINE_UPDATE, TIMELINE_DELETE, TIMELINE_CLEAR, TIMELINE_EXPAND_SUCCESS, TIMELINE_EXPAND_REQUEST, TIMELINE_EXPAND_FAIL, TIMELINE_CONNECT, TIMELINE_DISCONNECT, TIMELINE_UPDATE_QUEUE, TIMELINE_DEQUEUE, MAX_QUEUED_ITEMS, TIMELINE_SCROLL_TOP, TIMELINE_REPLACE, TIMELINE_INSERT, TIMELINE_CLEAR_FEED_ACCOUNT_ID } from '../actions/timelines';
const TRUNCATE_LIMIT = 40;
const TRUNCATE_SIZE = 20;
const TimelineRecord = ImmutableRecord({
  unread: 0,
  online: false,
  top: true,
  isLoading: false,
  hasMore: true,
  items: ImmutableOrderedSet(),
  queuedItems: ImmutableOrderedSet(),
  //max= MAX_QUEUED_ITEMS
  feedAccountId: null,
  totalQueuedItemsCount: 0,
  //used for queuedItems overflow for MAX_QUEUED_ITEMS+
  loadingFailed: false,
  isPartial: false
});
const initialState = ImmutableMap();

const getStatusIds = function () {
  let statuses = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ImmutableList();
  return statuses.map(status => status.get('id')).toOrderedSet();
};

const mergeStatusIds = function () {
  let oldIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ImmutableOrderedSet();
  let newIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ImmutableOrderedSet();
  return newIds.union(oldIds);
};

const addStatusId = function () {
  let oldIds = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ImmutableOrderedSet();
  let newId = arguments.length > 1 ? arguments[1] : undefined;
  return mergeStatusIds(oldIds, ImmutableOrderedSet([newId]));
}; // Like `take`, but only if the collection's size exceeds truncateLimit


const truncate = (items, truncateLimit, newSize) => items.size > truncateLimit ? items.take(newSize) : items;

const truncateIds = items => truncate(items, TRUNCATE_LIMIT, TRUNCATE_SIZE);

const setLoading = (state, timelineId, loading) => {
  return state.update(timelineId, TimelineRecord(), timeline => timeline.set('isLoading', loading));
}; // Keep track of when a timeline failed to load


const setFailed = (state, timelineId, failed) => {
  return state.update(timelineId, TimelineRecord(), timeline => timeline.set('loadingFailed', failed));
};

const expandNormalizedTimeline = (state, timelineId, statuses, next, isPartial, isLoadingRecent, isLoadingMore) => {
  let newIds = getStatusIds(statuses);
  let unseens = ImmutableOrderedSet();
  return state.withMutations(s => {
    s.update(timelineId, TimelineRecord(), timeline => timeline.withMutations(timeline => {
      timeline.set('isLoading', false);
      timeline.set('loadingFailed', false);
      timeline.set('isPartial', isPartial);
      if (!next && !isLoadingRecent) timeline.set('hasMore', false); // Pinned timelines can be replaced entirely

      if (timelineId.endsWith(':pinned')) {
        timeline.set('items', newIds);
        return;
      }

      if (!newIds.isEmpty()) {
        // we need to sort between queue and actual list to avoid
        // messing with user position in the timeline by inserting inseen statuses
        unseens = ImmutableOrderedSet();

        if (!isLoadingMore && timeline.items.count() > 0 && newIds.first() > timeline.items.first()) {
          unseens = newIds.subtract(timeline.items);
        }

        newIds = newIds.subtract(unseens);
        timeline.update('items', oldIds => {
          if (newIds.first() > oldIds.first()) {
            return mergeStatusIds(oldIds, newIds);
          } else {
            return mergeStatusIds(newIds, oldIds);
          }
        });
      }
    }));
    unseens.forEach(statusId => s.set(timelineId, updateTimelineQueue(s, timelineId, statusId).get(timelineId)));
  });
};

const updateTimeline = (state, timelineId, statusId) => {
  var _state$get, _state$get2, _state$get3;

  const top = (_state$get = state.get(timelineId)) === null || _state$get === void 0 ? void 0 : _state$get.top;
  const oldIds = ((_state$get2 = state.get(timelineId)) === null || _state$get2 === void 0 ? void 0 : _state$get2.items) || ImmutableOrderedSet();
  const unread = ((_state$get3 = state.get(timelineId)) === null || _state$get3 === void 0 ? void 0 : _state$get3.unread) || 0;
  if (oldIds.includes(statusId)) return state;
  const newIds = addStatusId(oldIds, statusId);
  return state.update(timelineId, TimelineRecord(), timeline => timeline.withMutations(timeline => {
    if (top) {
      // For performance, truncate items if user is scrolled to the top
      timeline.set('items', truncateIds(newIds));
    } else {
      timeline.set('unread', unread + 1);
      timeline.set('items', newIds);
    }
  }));
};

const updateTimelineQueue = (state, timelineId, statusId) => {
  var _state$get4, _state$get5, _state$get6;

  const queuedIds = ((_state$get4 = state.get(timelineId)) === null || _state$get4 === void 0 ? void 0 : _state$get4.queuedItems) || ImmutableOrderedSet();
  const listedIds = ((_state$get5 = state.get(timelineId)) === null || _state$get5 === void 0 ? void 0 : _state$get5.items) || ImmutableOrderedSet();
  const queuedCount = ((_state$get6 = state.get(timelineId)) === null || _state$get6 === void 0 ? void 0 : _state$get6.totalQueuedItemsCount) || 0;
  if (queuedIds.includes(statusId)) return state;
  if (listedIds.includes(statusId)) return state;
  return state.update(timelineId, TimelineRecord(), timeline => timeline.withMutations(timeline => {
    timeline.set('totalQueuedItemsCount', queuedCount + 1);
    timeline.set('queuedItems', addStatusId(queuedIds, statusId).take(MAX_QUEUED_ITEMS));
  }));
};

const shouldDelete = (timelineId, excludeAccount) => {
  if (!excludeAccount) return true;
  if (timelineId === "account:".concat(excludeAccount)) return false;
  if (timelineId.startsWith("account:".concat(excludeAccount, ":"))) return false;
  return true;
};

const deleteStatus = (state, statusId, accountId, references, excludeAccount) => {
  return state.withMutations(state => {
    state.keySeq().forEach(timelineId => {
      if (shouldDelete(timelineId, excludeAccount)) {
        state.updateIn([timelineId, 'items'], ids => ids.delete(statusId));
        state.updateIn([timelineId, 'queuedItems'], ids => ids.delete(statusId));
      }
    }); // Remove reblogs of deleted status

    references.forEach(ref => {
      deleteStatus(state, ref[0], ref[1], [], excludeAccount);
    });
  });
};

const clearTimeline = (state, timelineId) => {
  return state.set(timelineId, TimelineRecord());
};

const updateTop = (state, timelineId, top) => {
  return state.update(timelineId, TimelineRecord(), timeline => timeline.withMutations(timeline => {
    if (top) timeline.set('unread', 0);
    timeline.set('top', top);
  }));
};

const isReblogOf = (reblog, status) => reblog.reblog === status.id;

const statusToReference = status => [status.id, status.account];

const buildReferencesTo = (statuses, status) => statuses.filter(reblog => isReblogOf(reblog, status)).map(statusToReference);

const filterTimeline = (state, timelineId, relationship, statuses) => state.updateIn([timelineId, 'items'], ImmutableOrderedSet(), ids => ids.filterNot(statusId => statuses.getIn([statusId, 'account']) === relationship.id));

const filterTimelines = (state, relationship, statuses) => {
  return state.withMutations(state => {
    statuses.forEach(status => {
      if (status.get('account') !== relationship.id) return;
      const references = buildReferencesTo(statuses, status);
      deleteStatus(state, status.get('id'), status.get('account'), references, relationship.id);
    });
  });
};

const removeStatusFromGroup = (state, groupId, statusId) => {
  return state.updateIn(["group:".concat(groupId), 'items'], ImmutableOrderedSet(), ids => ids.delete(statusId));
};

const timelineDequeue = (state, timelineId) => {
  const top = state.getIn([timelineId, 'top']);
  return state.update(timelineId, TimelineRecord(), timeline => timeline.withMutations(timeline => {
    const queuedIds = timeline.queuedItems;
    timeline.update('items', ids => {
      const newIds = mergeStatusIds(ids, queuedIds);
      return top ? truncateIds(newIds) : newIds;
    });
    timeline.set('queuedItems', ImmutableOrderedSet());
    timeline.set('totalQueuedItemsCount', 0);
  }));
};

const timelineConnect = (state, timelineId) => {
  return state.update(timelineId, TimelineRecord(), timeline => timeline.set('online', true));
};

const timelineDisconnect = (state, timelineId) => {
  return state.update(timelineId, TimelineRecord(), timeline => timeline.withMutations(timeline => {
    timeline.set('online', false);
    const items = timeline.get('items', ImmutableOrderedSet());
    if (items.isEmpty()) return; // This is causing problems. Disable for now.
    // https://gitlab.com/soapbox-pub/soapbox-fe/-/issues/716
    // timeline.set('items', addStatusId(items, null));
  }));
};

const getTimelinesByVisibility = visibility => {
  switch (visibility) {
    case 'direct':
      return ['direct'];

    case 'public':
      return ['home', 'community', 'public'];

    default:
      return ['home'];
  }
}; // Given an OrderedSet of IDs, replace oldId with newId maintaining its position


const replaceId = (ids, oldId, newId) => {
  const list = ImmutableList(ids);
  const index = list.indexOf(oldId);

  if (index > -1) {
    return ImmutableOrderedSet(list.set(index, newId));
  } else {
    return ids;
  }
};

const importPendingStatus = (state, params, idempotencyKey) => {
  const statusId = "\u672Bpending-".concat(idempotencyKey);
  return state.withMutations(state => {
    const timelineIds = getTimelinesByVisibility(params.visibility);
    timelineIds.forEach(timelineId => {
      updateTimelineQueue(state, timelineId, statusId);
    });
  });
};

const replacePendingStatus = (state, idempotencyKey, newId) => {
  const oldId = "\u672Bpending-".concat(idempotencyKey); // Loop through timelines and replace the pending status with the real one

  return state.withMutations(state => {
    state.keySeq().forEach(timelineId => {
      state.updateIn([timelineId, 'items'], ids => replaceId(ids, oldId, newId));
      state.updateIn([timelineId, 'queuedItems'], ids => replaceId(ids, oldId, newId));
    });
  });
};

const importStatus = (state, status, idempotencyKey) => {
  return state.withMutations(state => {
    replacePendingStatus(state, idempotencyKey, status.id);
    const timelineIds = getTimelinesByVisibility(status.visibility);
    timelineIds.forEach(timelineId => {
      updateTimeline(state, timelineId, status.id);
    });
  });
};

const handleExpandFail = (state, timelineId) => {
  return state.withMutations(state => {
    setLoading(state, timelineId, false);
    setFailed(state, timelineId, true);
  });
};

export default function timelines() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case STATUS_CREATE_REQUEST:
      if (action.params.scheduled_at) return state;
      return importPendingStatus(state, action.params, action.idempotencyKey);

    case STATUS_CREATE_SUCCESS:
      if (action.status.scheduled_at) return state;
      return importStatus(state, action.status, action.idempotencyKey);

    case TIMELINE_EXPAND_REQUEST:
      return setLoading(state, action.timeline, true);

    case TIMELINE_EXPAND_FAIL:
      return handleExpandFail(state, action.timeline);

    case TIMELINE_EXPAND_SUCCESS:
      return expandNormalizedTimeline(state, action.timeline, fromJS(action.statuses), action.next, action.partial, action.isLoadingRecent, action.isLoadingMore);

    case TIMELINE_UPDATE:
      return updateTimeline(state, action.timeline, action.statusId);

    case TIMELINE_UPDATE_QUEUE:
      return updateTimelineQueue(state, action.timeline, action.statusId);

    case TIMELINE_DEQUEUE:
      return timelineDequeue(state, action.timeline);

    case TIMELINE_DELETE:
      return deleteStatus(state, action.id, action.accountId, action.references, action.reblogOf);

    case TIMELINE_CLEAR:
      return clearTimeline(state, action.timeline);

    case ACCOUNT_BLOCK_SUCCESS:
    case ACCOUNT_MUTE_SUCCESS:
      return filterTimelines(state, action.relationship, action.statuses);

    case ACCOUNT_UNFOLLOW_SUCCESS:
      return filterTimeline(state, 'home', action.relationship, action.statuses);

    case TIMELINE_SCROLL_TOP:
      return updateTop(state, action.timeline, action.top);

    case TIMELINE_CONNECT:
      return timelineConnect(state, action.timeline);

    case TIMELINE_DISCONNECT:
      return timelineDisconnect(state, action.timeline);

    case GROUP_REMOVE_STATUS_SUCCESS:
      return removeStatusFromGroup(state, action.groupId, action.id);

    case TIMELINE_REPLACE:
      return state.update('home', TimelineRecord(), timeline => timeline.withMutations(timeline => {
        timeline.set('items', ImmutableOrderedSet([]));
      })).update('home', TimelineRecord(), timeline => timeline.set('feedAccountId', action.accountId));

    case TIMELINE_INSERT:
      return state.update(action.timeline, TimelineRecord(), timeline => timeline.withMutations(timeline => {
        timeline.update('items', oldIds => {
          let oldIdsArray = oldIds.toArray();
          const existingSuggestionId = oldIdsArray.find(key => key.includes('末suggestions'));

          if (existingSuggestionId) {
            oldIdsArray = oldIdsArray.slice(1);
          }

          const positionInTimeline = sample([5, 6, 7, 8, 9]);
          oldIdsArray.splice(positionInTimeline, 0, "\u672Bsuggestions-".concat(oldIds.last()));
          return ImmutableOrderedSet(oldIdsArray);
        });
      }));

    case TIMELINE_CLEAR_FEED_ACCOUNT_ID:
      return state.update('home', TimelineRecord(), timeline => timeline.set('feedAccountId', null));

    default:
      return state;
  }
}