import { Map as ImmutableMap, OrderedSet as ImmutableOrderedSet } from 'immutable';
import { getSettings, changeSetting } from 'soapbox/actions/settings';

const getPinnedHosts = state => {
  const settings = getSettings(state); // return ImmutableList([]);

  return ImmutableOrderedSet(settings.getIn(['remote_timeline', 'pinnedHosts']));
};

const pinHost = (host, favicon) => (dispatch, getState) => {
  const state = getState();
  const pinnedHosts = getPinnedHosts(state);
  return dispatch(changeSetting(['remote_timeline', 'pinnedHosts'], pinnedHosts.add(ImmutableMap({
    host,
    favicon
  }))));
};

const unpinHost = host => (dispatch, getState) => {
  const state = getState();
  const pinnedHosts = getPinnedHosts(state);
  return dispatch(changeSetting(['remote_timeline', 'pinnedHosts'], pinnedHosts.filter(h => h.get('host') !== host)));
};

export { getPinnedHosts, pinHost, unpinHost };