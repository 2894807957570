import "core-js/modules/web.dom-collections.iterator.js";

/**
 * Static: functions related to static files.
 * @module soapbox/utils/static
 */
import { join } from 'path';
import * as BuildConfig from 'soapbox/build_config';
/** Gets the path to a file with build configuration being considered. */

export const joinPublicPath = function () {
  for (var _len = arguments.length, paths = new Array(_len), _key = 0; _key < _len; _key++) {
    paths[_key] = arguments[_key];
  }

  return join(BuildConfig.FE_SUBDIRECTORY, ...paths);
};