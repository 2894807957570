import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-collections.iterator.js";

/**
 * Functions for dealing with custom build configuration.
 */
import * as BuildConfig from 'soapbox/build_config';
/** Require a custom JSON file if it exists */

export const custom = function (filename) {
  let fallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (BuildConfig.NODE_ENV === 'test') return fallback; // @ts-ignore: yes it does

  const context = require.context('custom', false, /\.json$/);

  const path = "./".concat(filename, ".json");
  return context.keys().includes(path) ? context(path) : fallback;
};