// this file was prevaled

/**
 * Build config: configuration set at build time.
 * @module soapbox/build_config
 */
module.exports = {
  "NODE_ENV": "development",
  "BACKEND_URL": "https://bdx.town",
  "FE_SUBDIRECTORY": "/",
  "FE_BUILD_DIR": "static",
  "FE_INSTANCE_SOURCE_DIR": "instance"
};