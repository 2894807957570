import "core-js/modules/web.dom-collections.iterator.js";
import { isLoggedIn } from 'soapbox/utils/auth';
import { getFeatures } from 'soapbox/utils/features';
import { shouldHaveCard } from 'soapbox/utils/status';
import api, { getNextLink } from '../api';
import { setComposeToStatus } from './compose';
import { importFetchedStatus, importFetchedStatuses } from './importer';
import { openModal } from './modals';
import { deleteFromTimelines } from './timelines';
const STATUS_CREATE_REQUEST = 'STATUS_CREATE_REQUEST';
const STATUS_CREATE_SUCCESS = 'STATUS_CREATE_SUCCESS';
const STATUS_CREATE_FAIL = 'STATUS_CREATE_FAIL';
const STATUS_FETCH_SOURCE_REQUEST = 'STATUS_FETCH_SOURCE_REQUEST';
const STATUS_FETCH_SOURCE_SUCCESS = 'STATUS_FETCH_SOURCE_SUCCESS';
const STATUS_FETCH_SOURCE_FAIL = 'STATUS_FETCH_SOURCE_FAIL';
const STATUS_FETCH_REQUEST = 'STATUS_FETCH_REQUEST';
const STATUS_FETCH_SUCCESS = 'STATUS_FETCH_SUCCESS';
const STATUS_FETCH_FAIL = 'STATUS_FETCH_FAIL';
const STATUS_DELETE_REQUEST = 'STATUS_DELETE_REQUEST';
const STATUS_DELETE_SUCCESS = 'STATUS_DELETE_SUCCESS';
const STATUS_DELETE_FAIL = 'STATUS_DELETE_FAIL';
const CONTEXT_FETCH_REQUEST = 'CONTEXT_FETCH_REQUEST';
const CONTEXT_FETCH_SUCCESS = 'CONTEXT_FETCH_SUCCESS';
const CONTEXT_FETCH_FAIL = 'CONTEXT_FETCH_FAIL';
const STATUS_MUTE_REQUEST = 'STATUS_MUTE_REQUEST';
const STATUS_MUTE_SUCCESS = 'STATUS_MUTE_SUCCESS';
const STATUS_MUTE_FAIL = 'STATUS_MUTE_FAIL';
const STATUS_UNMUTE_REQUEST = 'STATUS_UNMUTE_REQUEST';
const STATUS_UNMUTE_SUCCESS = 'STATUS_UNMUTE_SUCCESS';
const STATUS_UNMUTE_FAIL = 'STATUS_UNMUTE_FAIL';
const STATUS_TRANSLATE_REQUEST = 'STATUS_TRANSLATE_REQUEST';
const STATUS_TRANSLATE_SUCCESS = 'STATUS_TRANSLATE_SUCCESS';
const STATUS_TRANSLATE_FAIL = 'STATUS_TRANSLATE_FAIL';
const STATUS_REVEAL = 'STATUS_REVEAL';
const STATUS_HIDE = 'STATUS_HIDE';
const STATUS_APPLY_FILTERS = 'STATUS_APPLY_FILTERS';

const statusExists = (getState, statusId) => {
  return (getState().statuses.get(statusId) || null) !== null;
};

const translateStatus = (statusId, language) => {
  return (dispatch, getState) => {
    dispatch({
      type: STATUS_TRANSLATE_REQUEST,
      statusId
    });
    return api(getState).request({
      url: "/api/v1/statuses/".concat(statusId, "/translations/").concat(language),
      method: 'get'
    }).then(_ref => {
      let {
        data: translation
      } = _ref;
      dispatch({
        type: STATUS_TRANSLATE_SUCCESS,
        statusId,
        language,
        text: translation.text
      });
      return translation;
    }).catch(error => {
      dispatch({
        type: STATUS_TRANSLATE_FAIL,
        statusId,
        error
      });
      throw error;
    });
  };
};

const createStatus = (params, idempotencyKey, statusId) => {
  return (dispatch, getState) => {
    dispatch({
      type: STATUS_CREATE_REQUEST,
      params,
      idempotencyKey,
      editing: !!statusId
    });
    return api(getState).request({
      url: statusId === null ? '/api/v1/statuses' : "/api/v1/statuses/".concat(statusId),
      method: statusId === null ? 'post' : 'put',
      data: params,
      headers: {
        'Idempotency-Key': idempotencyKey
      }
    }).then(_ref2 => {
      let {
        data: status
      } = _ref2;

      // The backend might still be processing the rich media attachment
      if (!status.card && shouldHaveCard(status)) {
        status.expectsCard = true;
      }

      dispatch(importFetchedStatus(status, idempotencyKey));
      dispatch({
        type: STATUS_CREATE_SUCCESS,
        status,
        params,
        idempotencyKey
      }); // Poll the backend for the updated card

      if (status.expectsCard) {
        const delay = 1000;

        const poll = function () {
          let retries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 5;
          api(getState).get("/api/v1/statuses/".concat(status.id)).then(response => {
            var _response$data;

            if ((_response$data = response.data) !== null && _response$data !== void 0 && _response$data.card) {
              dispatch(importFetchedStatus(response.data));
            } else if (retries > 0 && response.status === 200) {
              setTimeout(() => poll(retries - 1), delay);
            }
          }).catch(console.error);
        };

        setTimeout(() => poll(), delay);
      }

      return status;
    }).catch(error => {
      dispatch({
        type: STATUS_CREATE_FAIL,
        error,
        params,
        idempotencyKey,
        editing: !!statusId
      });
      throw error;
    });
  };
};

const editStatus = id => (dispatch, getState) => {
  let status = getState().statuses.get(id);

  if (status.poll) {
    status = status.set('poll', getState().polls.get(status.poll));
  }

  dispatch({
    type: STATUS_FETCH_SOURCE_REQUEST
  });
  api(getState).get("/api/v1/statuses/".concat(id, "/source")).then(response => {
    dispatch({
      type: STATUS_FETCH_SOURCE_SUCCESS
    });
    dispatch(setComposeToStatus(status, response.data.text, response.data.spoiler_text, response.data.content_type));
    dispatch(openModal('COMPOSE'));
  }).catch(error => {
    dispatch({
      type: STATUS_FETCH_SOURCE_FAIL,
      error
    });
  });
};

const fetchStatus = id => {
  return (dispatch, getState) => {
    const skipLoading = statusExists(getState, id);
    dispatch({
      type: STATUS_FETCH_REQUEST,
      id,
      skipLoading
    });
    return api(getState).get("/api/v1/statuses/".concat(id)).then(_ref3 => {
      let {
        data: status
      } = _ref3;
      dispatch(importFetchedStatus(status));
      dispatch({
        type: STATUS_FETCH_SUCCESS,
        status,
        skipLoading
      });
      return status;
    }).catch(error => {
      dispatch({
        type: STATUS_FETCH_FAIL,
        id,
        error,
        skipLoading,
        skipAlert: true
      });
    });
  };
};

const deleteStatus = function (id) {
  let withRedraft = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return (dispatch, getState) => {
    if (!isLoggedIn(getState)) return null;
    let status = getState().statuses.get(id);

    if (status.poll) {
      status = status.set('poll', getState().polls.get(status.poll));
    }

    dispatch({
      type: STATUS_DELETE_REQUEST,
      params: status
    });
    return api(getState).delete("/api/v1/statuses/".concat(id)).then(response => {
      dispatch({
        type: STATUS_DELETE_SUCCESS,
        id
      });
      dispatch(deleteFromTimelines(id));

      if (withRedraft) {
        var _response$data$plerom;

        dispatch(setComposeToStatus(status, response.data.text, response.data.spoiler_text, (_response$data$plerom = response.data.pleroma) === null || _response$data$plerom === void 0 ? void 0 : _response$data$plerom.content_type, withRedraft));
        dispatch(openModal('COMPOSE'));
      }
    }).catch(error => {
      dispatch({
        type: STATUS_DELETE_FAIL,
        params: status,
        error
      });
    });
  };
};

const updateStatus = status => dispatch => dispatch(importFetchedStatus(status));

const fetchContext = id => (dispatch, getState) => {
  dispatch({
    type: CONTEXT_FETCH_REQUEST,
    id
  });
  return api(getState).get("/api/v1/statuses/".concat(id, "/context")).then(_ref4 => {
    let {
      data: context
    } = _ref4;

    if (Array.isArray(context)) {
      // Mitra: returns a list of statuses
      dispatch(importFetchedStatuses(context));
    } else if (typeof context === 'object') {
      // Standard Mastodon API returns a map with `ancestors` and `descendants`
      const {
        ancestors,
        descendants
      } = context;
      const statuses = ancestors.concat(descendants);
      dispatch(importFetchedStatuses(statuses));
      dispatch({
        type: CONTEXT_FETCH_SUCCESS,
        id,
        ancestors,
        descendants
      });
    } else {
      throw context;
    }

    return context;
  }).catch(error => {
    var _error$response;

    if (((_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) === 404) {
      dispatch(deleteFromTimelines(id));
    }

    dispatch({
      type: CONTEXT_FETCH_FAIL,
      id,
      error,
      skipAlert: true
    });
  });
};

const fetchNext = (statusId, next) => async (dispatch, getState) => {
  const response = await api(getState).get(next);
  dispatch(importFetchedStatuses(response.data));
  dispatch({
    type: CONTEXT_FETCH_SUCCESS,
    id: statusId,
    ancestors: [],
    descendants: response.data
  });
  return {
    next: getNextLink(response)
  };
};

const fetchAncestors = id => async (dispatch, getState) => {
  const response = await api(getState).get("/api/v1/statuses/".concat(id, "/context/ancestors"));
  dispatch(importFetchedStatuses(response.data));
  return response;
};

const fetchDescendants = id => async (dispatch, getState) => {
  const response = await api(getState).get("/api/v1/statuses/".concat(id, "/context/descendants"));
  dispatch(importFetchedStatuses(response.data));
  return response;
};

const fetchStatusWithContext = id => async (dispatch, getState) => {
  const features = getFeatures(getState().instance);

  if (features.paginatedContext) {
    await dispatch(fetchStatus(id));
    const responses = await Promise.all([dispatch(fetchAncestors(id)), dispatch(fetchDescendants(id))]);
    dispatch({
      type: CONTEXT_FETCH_SUCCESS,
      id,
      ancestors: responses[0].data,
      descendants: responses[1].data
    });
    const next = getNextLink(responses[1]);
    return {
      next
    };
  } else {
    await Promise.all([dispatch(fetchContext(id)), dispatch(fetchStatus(id))]);
    return {
      next: undefined
    };
  }
};

const muteStatus = id => (dispatch, getState) => {
  if (!isLoggedIn(getState)) return;
  dispatch({
    type: STATUS_MUTE_REQUEST,
    id
  });
  api(getState).post("/api/v1/statuses/".concat(id, "/mute")).then(() => {
    dispatch({
      type: STATUS_MUTE_SUCCESS,
      id
    });
  }).catch(error => {
    dispatch({
      type: STATUS_MUTE_FAIL,
      id,
      error
    });
  });
};

const unmuteStatus = id => (dispatch, getState) => {
  if (!isLoggedIn(getState)) return;
  dispatch({
    type: STATUS_UNMUTE_REQUEST,
    id
  });
  api(getState).post("/api/v1/statuses/".concat(id, "/unmute")).then(() => {
    dispatch({
      type: STATUS_UNMUTE_SUCCESS,
      id
    });
  }).catch(error => {
    dispatch({
      type: STATUS_UNMUTE_FAIL,
      id,
      error
    });
  });
};

const toggleMuteStatus = status => (dispatch, getState) => {
  if (status.muted) {
    dispatch(unmuteStatus(status.id));
  } else {
    dispatch(muteStatus(status.id));
  }
};

const hideStatus = ids => {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  return {
    type: STATUS_HIDE,
    ids
  };
};

const revealStatus = ids => {
  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  return {
    type: STATUS_REVEAL,
    ids
  };
};

const toggleStatusHidden = status => {
  if (status.hidden) {
    return revealStatus(status.id);
  } else {
    return hideStatus(status.id);
  }
};

export { STATUS_CREATE_REQUEST, STATUS_CREATE_SUCCESS, STATUS_CREATE_FAIL, STATUS_FETCH_SOURCE_REQUEST, STATUS_FETCH_SOURCE_SUCCESS, STATUS_FETCH_SOURCE_FAIL, STATUS_FETCH_REQUEST, STATUS_FETCH_SUCCESS, STATUS_FETCH_FAIL, STATUS_DELETE_REQUEST, STATUS_DELETE_SUCCESS, STATUS_DELETE_FAIL, CONTEXT_FETCH_REQUEST, CONTEXT_FETCH_SUCCESS, CONTEXT_FETCH_FAIL, STATUS_MUTE_REQUEST, STATUS_MUTE_SUCCESS, STATUS_MUTE_FAIL, STATUS_UNMUTE_REQUEST, STATUS_UNMUTE_SUCCESS, STATUS_UNMUTE_FAIL, STATUS_REVEAL, STATUS_HIDE, STATUS_TRANSLATE_REQUEST, STATUS_TRANSLATE_SUCCESS, STATUS_TRANSLATE_FAIL, STATUS_APPLY_FILTERS, createStatus, editStatus, fetchStatus, deleteStatus, updateStatus, fetchContext, fetchNext, fetchAncestors, fetchDescendants, fetchStatusWithContext, muteStatus, unmuteStatus, toggleMuteStatus, hideStatus, revealStatus, toggleStatusHidden, translateStatus };