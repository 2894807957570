import { defineMessages } from 'react-intl';
import snackbar from 'soapbox/actions/snackbar';
import { getFilters } from 'soapbox/selectors';
import { isLoggedIn } from 'soapbox/utils/auth';
import { getFeatures } from 'soapbox/utils/features';
import api from '../api';
import { STATUS_APPLY_FILTERS } from './statuses';
const FILTERS_FETCH_REQUEST = 'FILTERS_FETCH_REQUEST';
const FILTERS_FETCH_SUCCESS = 'FILTERS_FETCH_SUCCESS';
const FILTERS_FETCH_FAIL = 'FILTERS_FETCH_FAIL';
const FILTERS_CREATE_REQUEST = 'FILTERS_CREATE_REQUEST';
const FILTERS_CREATE_SUCCESS = 'FILTERS_CREATE_SUCCESS';
const FILTERS_CREATE_FAIL = 'FILTERS_CREATE_FAIL';
const FILTERS_DELETE_REQUEST = 'FILTERS_DELETE_REQUEST';
const FILTERS_DELETE_SUCCESS = 'FILTERS_DELETE_SUCCESS';
const FILTERS_DELETE_FAIL = 'FILTERS_DELETE_FAIL';
const messages = defineMessages({
  added: {
    "id": "filters.added",
    "defaultMessage": "Filter added."
  },
  removed: {
    "id": "filters.removed",
    "defaultMessage": "Filter deleted."
  }
});

const fetchFilters = () => async (dispatch, getState) => {
  if (!isLoggedIn(getState)) return;
  const state = getState();
  const instance = state.instance;
  const features = getFeatures(instance);
  if (!features.filters) return;
  dispatch({
    type: FILTERS_FETCH_REQUEST,
    skipLoading: true
  });

  try {
    const {
      data
    } = await api(getState).get('/api/v1/filters');
    await dispatch({
      type: FILTERS_FETCH_SUCCESS,
      filters: data,
      skipLoading: true
    });
    const filters = getFilters(getState(), null);
    dispatch({
      type: STATUS_APPLY_FILTERS,
      filters
    });
  } catch (err) {
    dispatch({
      type: FILTERS_FETCH_FAIL,
      err,
      skipLoading: true,
      skipAlert: true
    });
  }
};

const createFilter = (phrase, expires_at, context, whole_word, irreversible) => (dispatch, getState) => {
  dispatch({
    type: FILTERS_CREATE_REQUEST
  });
  return api(getState).post('/api/v1/filters', {
    phrase,
    context,
    irreversible,
    whole_word,
    expires_at
  }).then(response => {
    dispatch({
      type: FILTERS_CREATE_SUCCESS,
      filter: response.data
    });
    dispatch(snackbar.success(messages.added));
  }).catch(error => {
    dispatch({
      type: FILTERS_CREATE_FAIL,
      error
    });
  });
};

const deleteFilter = id => (dispatch, getState) => {
  dispatch({
    type: FILTERS_DELETE_REQUEST
  });
  return api(getState).delete("/api/v1/filters/".concat(id)).then(response => {
    dispatch({
      type: FILTERS_DELETE_SUCCESS,
      filter: response.data
    });
    dispatch(snackbar.success(messages.removed));
  }).catch(error => {
    dispatch({
      type: FILTERS_DELETE_FAIL,
      error
    });
  });
};

export { FILTERS_FETCH_REQUEST, FILTERS_FETCH_SUCCESS, FILTERS_FETCH_FAIL, FILTERS_CREATE_REQUEST, FILTERS_CREATE_SUCCESS, FILTERS_CREATE_FAIL, FILTERS_DELETE_REQUEST, FILTERS_DELETE_SUCCESS, FILTERS_DELETE_FAIL, fetchFilters, createFilter, deleteFilter };