function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import api from '../api';
import { fetchRelationships } from './accounts';
import { importFetchedAccounts } from './importer';
const DIRECTORY_FETCH_REQUEST = 'DIRECTORY_FETCH_REQUEST';
const DIRECTORY_FETCH_SUCCESS = 'DIRECTORY_FETCH_SUCCESS';
const DIRECTORY_FETCH_FAIL = 'DIRECTORY_FETCH_FAIL';
const DIRECTORY_EXPAND_REQUEST = 'DIRECTORY_EXPAND_REQUEST';
const DIRECTORY_EXPAND_SUCCESS = 'DIRECTORY_EXPAND_SUCCESS';
const DIRECTORY_EXPAND_FAIL = 'DIRECTORY_EXPAND_FAIL';

const fetchDirectory = params => (dispatch, getState) => {
  dispatch(fetchDirectoryRequest());
  return api(getState).get('/api/v1/directory', {
    params: _objectSpread(_objectSpread({}, params), {}, {
      limit: 20
    })
  }).then(_ref => {
    let {
      data
    } = _ref;
    dispatch(importFetchedAccounts(data));
    dispatch(fetchDirectorySuccess(data));
    dispatch(fetchRelationships(data.map(x => x.id)));
  }).catch(error => dispatch(fetchDirectoryFail(error)));
};

const fetchDirectoryRequest = () => ({
  type: DIRECTORY_FETCH_REQUEST
});

const fetchDirectorySuccess = accounts => ({
  type: DIRECTORY_FETCH_SUCCESS,
  accounts
});

const fetchDirectoryFail = error => ({
  type: DIRECTORY_FETCH_FAIL,
  error
});

const expandDirectory = params => (dispatch, getState) => {
  dispatch(expandDirectoryRequest());
  const loadedItems = getState().user_lists.directory.items.size;
  return api(getState).get('/api/v1/directory', {
    params: _objectSpread(_objectSpread({}, params), {}, {
      offset: loadedItems,
      limit: 20
    })
  }).then(_ref2 => {
    let {
      data
    } = _ref2;
    dispatch(importFetchedAccounts(data));
    dispatch(expandDirectorySuccess(data));
    dispatch(fetchRelationships(data.map(x => x.id)));
  }).catch(error => dispatch(expandDirectoryFail(error)));
};

const expandDirectoryRequest = () => ({
  type: DIRECTORY_EXPAND_REQUEST
});

const expandDirectorySuccess = accounts => ({
  type: DIRECTORY_EXPAND_SUCCESS,
  accounts
});

const expandDirectoryFail = error => ({
  type: DIRECTORY_EXPAND_FAIL,
  error
});

export { DIRECTORY_FETCH_REQUEST, DIRECTORY_FETCH_SUCCESS, DIRECTORY_FETCH_FAIL, DIRECTORY_EXPAND_REQUEST, DIRECTORY_EXPAND_SUCCESS, DIRECTORY_EXPAND_FAIL, fetchDirectory, fetchDirectoryRequest, fetchDirectorySuccess, fetchDirectoryFail, expandDirectory, expandDirectoryRequest, expandDirectorySuccess, expandDirectoryFail };